/*--jodit inline link pop-up style--*/

.jodit .jodit-popup {
  box-shadow: 3px 3px 12px 2px rgba(155, 157, 159, 0.3);
  border-radius: 8px;
}
.jodit .jodit-popup .jodit-popup__content {
  padding: 6px;
  border-radius: 8px;
}

.jodit .jodit-popup .jodit-popup__content .jodit-ui-group__brush {
  display: none;
}

.jodit .jodit-popup .jodit-popup__content .jodit-ui-group__file {
  display: none;
}
.jodit .jodit-toolbar-button__button {
  border-radius: 8px;
}
.jodit .jodit-popup .jodit-toolbar-button .jodit-icon {
  height: auto;
  width: auto;
  stroke: none;
}

/*jodit popup form style*/

.jodit-custom-popup {
  padding: 8px;
}

.jodit-custom-popup .jodit-ui-input__label {
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #050b0e;
}

.jodit-custom-popup .jodit-ui-input__wrapper {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.jodit-custom-popup .jodit-ui-input_focused_true .jodit-ui-input__wrapper {
  border: 2px solid #050b0e;
}
.jodit-custom-popup .jodit-ui-input__wrapper .jodit-ui-input__input {
  border-radius: 8px;
}

.jodit-custom-popup .jodit-ui-block_align_full {
  justify-content: flex-end;
  margin-bottom: 0;
}

.jodit-custom-popup .jodit-ui-button_variant_primary {
  background-color: #050b0e;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.jodit-custom-popup .jodit-ui-button_variant_default {
  background-color: transparent;
  text-decoration: underline;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin-right: 8px;
}

.jodit-custom-popup .jodit-ui-button {
  height: 28px;
}
